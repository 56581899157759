








































































































































































import { Mixin_list } from "@/mixin";
import { api_city, city } from "@/http/city";
import { api_store, store } from "@/http/store";
import { Component } from "vue-property-decorator";
import Page from "@/components/page/index.vue";
import Table from "@/components/table/index.vue";
import DatePicker from "@/components/datePicker/index.vue";

@Component({
  components: {
    Page,
    Table,
    DatePicker,
  },
})
export default class extends Mixin_list<store>(api_store.get_list) {
  filter = {
    update_time_after: "",
    update_time_before: "",
    name: "",
    id: "",
  };
  resetFilter = {
    ...this.filter,
  };
  columns = [
    {
      label: "信息",
      slot: "info",
      prop: "info",
    },
    {
      label: "ID",
      prop: "id",
    },
    {
      label: "状态",
      prop: "status",
      slot: "status",

    },
    {
      label: "上架时间",
      prop: "create_time",

    },
    {
      label: "详细地址",
      prop: "address",
      slot: "address",

    },
    {
      label: "设备编号",
      prop: "device",
    },
    {
      label: "格子数",
      prop: "number",
    },
    {
      label: "操作",
      prop: "",
      slot: "operation",
    },
  ];
  getImageUrl(cover: string) {
    if (cover && typeof cover === "string") {
      // 将字符串按逗号分割成数组
      const coverIds = cover.split(",");
      // 取数组的第一个元素作为图片ID
      const coverId = coverIds[0].trim();
      // 拼接图片URL
      return `https://images.xiyuebiaoju.com/${coverId}`;
    }
  }
  status(status: number) {
    switch (status) {
      case 1:
        return "已上架";
      case 0:
        return "已下架";
    }
  }
  address(data: any) {
    return `${data.province}${data.city}${data.region}${data.address}`;
  }
  onUpdateRange([startStr, endStr]: [string, string]) {
    this.filter.update_time_after = startStr;
    this.filter.update_time_before = endStr;
  }
  reset() {
    this.filter = { ...this.filter, ...this.resetFilter };
    (this.$refs.datePicker as Vue & { clearDates: () => void }).clearDates();
    this.find();
  }


  async handleClick(type: string, row: any) {
    switch (type) {
      case "status":
        await api_store.edit({ ...row, status: row.status === 0 ? 1 : 0 });
        this.get_list();
        return;
      case "edit":
        await api_store.edit({ ...row });
        this.get_list();
        return;
    }
  }

  created() {
    // this.get_city_list();
  }

  click_cell(row: store) {
    return this.$router.push(`/store/edit/?id=${row.id}`);
  }


  async del(id: number) {
    await api_store.del_one(id);
    this.get_list();
  }
  setBox(row:any){
    this.$confirm('此操作会进行柜子初始化, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          const {device,number} = row;
          const data={
            cabinetId:device,
            number:number
          }
          api_store.set_box(data).then(()=>{
            // this.$message({
            //   type: 'success',
            //   message: '初始化成功!'
            // });
          }).catch(()=>{
            this.$message({
              type: 'info',
              message: '初始化失败!'
            });
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消初始化'
          });          
        });
  }
  //   async edit(id: number) {
  //     await api_store.edit(id);
  //   }
}
